<template>
<div>

  <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title=""
    >
      <b-row>
        <b-col md="6">
          <h4>Add Project Site Details</h4>
        </b-col>
        <!-- <b-col md="2" v-if="$store.getters.currentUser.role == 'administrator'">
          <b-form-group
              label="Contract Type"
              class="required"
            >
          </b-form-group>
        </b-col> -->
        <b-col md="6" v-if="$store.getters.currentUser.role == 'administrator'">

            <div class="demo-inline-spacing mb-2">

              <b-form-radio class="mb-0 mt-0" name="site_type" value="hit_count" v-model="form.site_type" @change="resetSeverality()">
                Headcount Based
              </b-form-radio>

              <b-form-radio class="mb-0 mt-0" name="site_type" value="performance_basis" v-model="form.site_type" @change="resetSeverality()">
                Performance Based
              </b-form-radio>
            </div>
          

        </b-col>
      </b-row>
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

        <div class="inner_dv mt-2">
          <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 150px">SITE DETAILS</legend>
            <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">

            <!-- <b-row>
              <b-col md="6">
                
                

              </b-col>
            </b-row> -->

            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Project Site"
                  class="required legend_top_space_mobile"
                >
                  <b-form-input
                    placeholder="2 to 50 characters"
                    v-model="form.site_name"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Prefix"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 10 characters"
                    v-model="form.prefix"
                  />
                </b-form-group>
              </b-col>

              

            </b-row>

            
            <b-row>

              

              <b-col md="6">
                <b-form-group
                  label="Address"
                  class="required"
                >

                  <!-- <vue-google-autocomplete
                    id="map"
                    classname="form-control"
                    placeholder=""
                    v-on:placechanged="getAddressData"
                    :country="['sg','tha']"
                    v-model="form.address"
                >
                </vue-google-autocomplete> Removed as per Issac Feedback 23/07/2024 -->
                  <b-form-input placeholder="" v-model="form.address" />
                </b-form-group>

                
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Postal Code"
                  class="required"
                >
                  <b-form-input
                    
                    placeholder=""
                    v-model="form.postal_code"
                  
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              

              <b-col md="6">
                <b-form-group
                  label="Country"
                  class="required"
                >
                  <!-- <b-form-input
                    
                    placeholder="upto 50 characters"
                    v-model="form.country"
                  /> -->

                  <b-form-select v-model="form.country">

                    <b-form-select-option value="" disabled>Select Country</b-form-select-option>
                    <b-form-select-option value="Singapore">Singapore</b-form-select-option>
                    <b-form-select-option value="Thailand">Thailand</b-form-select-option>
                  </b-form-select>

                </b-form-group>
              </b-col>
                            
              <b-col md="6">
                <b-form-group
                  label="Managing Agent Name"
                  
                >
                 
                  <vue-simple-suggest
                    v-model="form.managing_agent_name"
                    :list="agents"
                    :filter-by-query="true"
                    placeholder="Max 50 characters"
                    :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
                    >
                    
                  </vue-simple-suggest>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row >
              
              <b-col md="6">
                <b-form-group
                  label="Department"
                  class="required"
                >
                 
                  <vue-simple-suggest
                    v-model="form.department"
                    :list="departments"
                    :filter-by-query="true"
                    placeholder="Max 50 characters"
                    :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
                    >
                    
                  </vue-simple-suggest>
                </b-form-group>
              </b-col>

              <b-col md="3">
                  <b-form-checkbox
                    v-model="form.no_photo_reporting"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning mt-3 margin_top_zero_mobile"
                    @input="form.enable_selfie = 'no';"
                  >
                    No Photo Reporting
                  </b-form-checkbox>
                  
              </b-col>

              <b-col md="3">
                  <b-form-checkbox
                    v-model="form.group_filter"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning mt-3 mobile_one_rem_top"
                    
                  >
                    Group Filter
                  </b-form-checkbox>
                  
              </b-col>

            </b-row>

            <b-row>

              <b-col md="12">

                <p class="text-warning mt-2">Note: Hold the marker and move to precise location.</p>

                <gmap-map
                    ref="googleMap"
                    :center="mapStartLocation"
                    :zoom="zoom"
                    :options="{scrollwheel: false}"
                    
                    style="width:100%;  height: 400px; overflow: auto;"
                  >
                    <gmap-polygon 
                      :paths="paths" 
                      :editable="true" 
                      :draggable="true"
                      @paths_changed="updateEdited($event)">
                    </gmap-polygon>

                </gmap-map>
              </b-col>
            </b-row>


          </div>
        </div>

            
            <!-- <b-row class="mb-0 mt-2">
              <b-col md="12">
                <h4 class="card-title">Additional Settings</h4>
              </b-col>
            </b-row> -->

            <div class="inner_dv mt-2">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" >ADDITIONAL SETTINGS</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">
                  
                  <b-row class="mb-0" v-if="showHideMenu('Schedule Work', 'parent') || showHideMenu('SR Tasks', 'child') || showHideMenu('Schedule Works', 'child') || showHideMenu('Service Request', 'child') || showHideMenu('Feedback System', 'parent')">
                   
                    <b-col md="6">
                      <b-form-checkbox
                        v-model="form.task_status_delay"
                        value="yes"
                        unchecked-value="no"
                        class="mb-0 mt-1"
                        @change="form.task_status_delay_min = ''"
                        
                      >
                        Task Status Update Delay
                      </b-form-checkbox>

                    </b-col>
                    
                    <b-col md="6" v-if="form.task_status_delay == 'yes'">

                      <b-form-group
                        label="No of minutes"
                        class="required mobile_one_rem_top"
                      >
                        <b-form-input
                          placeholder="0"
                          v-model="form.task_status_delay_min"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-checkbox
                        v-model="form.start_expired_task"
                        value="yes"
                        unchecked-value="no"
                        class="mb-0 mt-0"
                      >
                        Allow Users to start expired tasks
                      </b-form-checkbox>

                    </b-col>

                    <b-col md="6">
                      <b-form-checkbox
                        v-model="form.restart_failed_task"
                        value="yes"
                        unchecked-value="no"
                        class="mb-0 mt-1"
                      >
                        Allow Users to restart Failed tasks
                      </b-form-checkbox>

                    </b-col>
                  </b-row>
                  
                  <b-row class="mb-0 mt-2" v-if="showHideMenu('Attendance', 'child') || showHideMenu('Defects', 'child') || showHideMenu('Defect', 'child')">
                    <b-col md="6" v-if="showHideMenu('Attendance', 'child')">
                      <b-form-checkbox
                        v-model="form.allow_attendance"
                        value="yes"
                        unchecked-value="no"
                        class="mb-0 mt-0"
                        @change="attendanceModeReset($event)"
                        
                      >
                        Allow Attendance
                      </b-form-checkbox>

                    </b-col>

                    <b-col md="6" v-if="showHideMenu('Defects', 'child') || showHideMenu('Defect', 'child')">
                      <b-form-checkbox
                        v-model="form.additional_info_defect"
                        value="yes"
                        unchecked-value="no"
                        class="mb-0 mt-1"
                      >
                        Additional Info on Defect
                      </b-form-checkbox>

                    </b-col>
                  </b-row>

                  
                  
                  <b-row class="mt-2 mb-2" v-if="form.allow_attendance == 'yes'">
                    <b-col md="12">
                      
                      <b-form-group
                        label="Attendance Mode"
                        class="required mb-0"
                      >
                      </b-form-group>
                      <!-- <div :id="$store.state.appConfig.layout.skin == 'dark' ? 'borderMap' : 'lightborderMap'"> -->
                        
                        <b-row>
                          <b-col md="6">

                            <div class="demo-inline-spacing">
                              <b-form-radio
                                class="mb-0 mt-1"
                                @change="changeAttendance()"
                                name="some-radios"
                                value="camera"
                                v-model="form.attendance_mode"
                              >
                                Camera
                              </b-form-radio>

                              <b-form-radio
                                class="mb-0 mt-1"
                                @change="changeAttendance()"
                                name="some-radios"
                                value="gps"
                                v-model="form.attendance_mode"
                              >
                                GPS
                              </b-form-radio>
                            </div>
                          </b-col>
                          <b-col md="6">

                            <b-form-checkbox
                              v-model="form.nfc_mode"
                              value="yes"
                              unchecked-value="no"
                              class="mb-0 mt-1"
                              
                            >
                              Allow NFC Mode
                            </b-form-checkbox>
                          </b-col>
                        </b-row>
                        
                        

                        <b-row>
                          <b-col md="12" class="mt-2 mb-1" v-if="form.attendance_mode == 'gps'">
                            <b-form-group
                              label="Radius (in meter)"
                              class="required"
                            >
                              <b-form-input
                                placeholder=""
                                v-model="form.radius"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col md="6" class="mt-2 mb-1" v-if="form.no_photo_reporting == 'no'">
                            <b-form-checkbox
                              v-model="form.enable_selfie"
                              value="yes"
                              unchecked-value="no"
                              class="mb-0 mt-1"
                            >
                              Enable Selfie
                            </b-form-checkbox>
                          </b-col>

                          <b-col md="6" class="mt-2 mb-1" v-if="form.attendance_mode == 'camera'">
                            <b-form-checkbox
                              v-model="form.timestamp_on_image"
                              value="yes"
                              unchecked-value="no"
                              class="mb-0 mt-1"
                              
                            >
                              Show Timestamp on Image
                            </b-form-checkbox>
                          </b-col>


                          <b-col md="6" class="mt-2 mb-1">
                            <b-form-checkbox
                              v-model="form.attendance_on_day_off"
                              value="yes"
                              unchecked-value="no"
                              class="mb-0 mt-1"
                              
                              
                            >
                              Allow Attendance on Day OFF
                            </b-form-checkbox>

                          </b-col>
                  

                        </b-row>


                      <!-- </div> -->
                      

                    </b-col>
                  </b-row>

                  <b-row class="mb-2 mt-2 margin_top_zero_mobile" v-if="form.allow_attendance == 'yes' && showHideMenu('Attendance', 'child')">
                    <b-col md="6">
                      
                      <b-form-group label="Grace Period" class="required mb-0">
                        
                        <b-form-input placeholder="Enter Grace Period in Minutes (0-59)" v-model="form.grace_period" />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  

                  <!-- <b-row class="mt-2 margin_top_zero_mobile">
                    <b-col md="6">
                      <b-form-group label="KPI Performance">
                        <b-form-input placeholder="KPI Performance" v-model="form.kpi_performance" autocomplete="off"/>
                      </b-form-group>
                    </b-col>
                  </b-row> -->
                  
                  <b-row class="mb-2 mt-2">
                    <b-col md="6">
                      
                      <b-form-group
                        label="Date Format"
                        class="required mb-0"
                      >
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            class="mb-0 mt-1"
                            
                            name="some-radios1"
                            value="MM/DD/YYYY"
                            v-model="form.pdf_date_format"
                          >
                            {{ datetype1() }}
                          </b-form-radio>

                          <b-form-radio
                            class="mb-0 mt-1"
                            
                            name="some-radios1"
                            value="DD/MM/YYYY"
                            v-model="form.pdf_date_format"
                          >
                            {{ datetype2() }}
                          </b-form-radio>

                          <b-form-radio
                            class="mb-0 mt-1"
                            
                            name="some-radios1"
                            value="DD MMM YYYY"
                            v-model="form.pdf_date_format"
                          >
                            {{ datetype3() }}
                          </b-form-radio>

                          <b-form-radio
                            class="mb-0 mt-1"
                            
                            name="some-radios1"
                            value="MMM DD YYYY"
                            v-model="form.pdf_date_format"
                          >
                            {{ datetype4() }}
                          </b-form-radio>
                        </div>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row class="mb-2 mt-2 margin_top_zero_mobile">
                    <b-col md="6">
                      
                      <b-form-group
                        label="Time Format"
                        class="required mb-0"
                      >
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            class="mb-0 mt-1"
                            
                            name="some-radios2"
                            value="hh:mm A"
                            v-model="form.pdf_time_format"
                          >
                            {{ '01:00 PM' }}
                          </b-form-radio>

                          <b-form-radio
                            class="mb-0 mt-1"
                            
                            name="some-radios2"
                            value="HH:mm"
                            v-model="form.pdf_time_format"
                          >
                            {{ '13:00' }}
                          </b-form-radio>

                          
                        </div>
                      </b-form-group>

                    </b-col>
                  </b-row>
                  
                  

                  <b-row class="mb-2 mt-2" v-if="form.site_type == 'performance_basis'">
                    <b-col md="8">
                      
                      <b-row class="mb-1">
                          <b-col md="8">
                            <b-form-group
                              label="Severity Levels"
                              class="mb-0 required"
                            >
                            </b-form-group>

                          </b-col>


                          <b-col md="4" >
                            <b-button
                                variant="warning"
                                class="mt-0 ml-50 mr-0 float-left"
                                @click="addSeverality()"
                              >
                              <feather-icon icon="PlusIcon" />
                              
                            </b-button>
                          </b-col>
                      </b-row>

                      <b-row v-for="(m,index) in form.severality_levels" :key="index + 1480" class="mb-1">

                        <b-col md="4">
                          <b-form-input placeholder="Level Name" v-model="m.name" class="mb-1"/>
                        </b-col>
                        <b-col md="4">
                          <b-form-input placeholder="No. of Minute" v-model="m.minute" />
                        </b-col>
                        <b-col md="4">
                          <b-button
                              variant="danger"
                              class="ml-50 mr-0 float-left"
                              @click="removeSeverality(index)"
                            >
                            <feather-icon icon="XIcon" />
                            
                          </b-button>
                        </b-col>
                      </b-row>



                    </b-col>
                  </b-row>

                  

                  
                  
                  <b-row class="mt-2 margin_top_zero_mobile">
                    <b-col md="12">
                      <b-form-group
                        label="Remarks"
                        
                      >
                        <b-form-textarea
                          placeholder="upto 1000 characters"
                          rows="3"
                          v-model="form.remarks"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  
                </div>
            </div>

            <!-- <b-row class="mb-1">
              <b-col md="12">
                <h4 class="card-title">Shift Detail</h4>
              </b-col>
            </b-row> -->

            <div class="inner_dv" v-if="showHideMenu('Quality Management', 'parent')">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 200px" >Quality Management </legend>
              <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">
                  <b-row class="mt-2 margin_top_zero_mobile">
                    <b-col md="6">
                      <b-form-group label="QM KPI Performance (%)" class="required">
                        <b-form-input placeholder="0 - 100%" v-model="form.kpi_performance" autocomplete="off"/>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Validator" class="">
                        <b-form-select v-model="form.qm_validator">
                          <b-form-select-option value='' >N/A</b-form-select-option>
                          <b-form-select-option :value="role.role" v-for="(role, index) in kpi_roles" :key="role._id + index">{{role.name}}</b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="6" >
                      <b-form-group label="QI Rating" class="required">
                        <b-form-select v-model="form.qi">
                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                          <b-form-select-option value="Star">Star</b-form-select-option>
                          <b-form-select-option value="Number">Number</b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="QI Star/Number Count" class="required">
                        <b-form-select v-model="form.qi_number">
                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                          <b-form-select-option :value="n" v-for="n in 10" :key="n">{{n}}</b-form-select-option>                          
                        </b-form-select>
                      </b-form-group>
                    </b-col>

                  </b-row>
              </div>
            </div>

            <div class="inner_dv" v-if="showHideMenu('Inventory', 'parent')">
                <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 200px" >Inventory Settings </legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">
                    <b-row class="mt-2 margin_top_zero_mobile">
                      <b-col md="4">

                        <b-form-checkbox
                          v-model="form.inventory"
                          value="yes"
                          unchecked-value="no"
                          class="mb-0 mt-0"
                          @change="inventorySetReset()"
                        >
                          Allow Inventory
                        </b-form-checkbox>
                        
                      </b-col>
                      <b-col md="4">

                        <b-form-checkbox
                          v-model="form.multiple_delivery"
                          value="yes"
                          unchecked-value="no"
                          class="mb-0 mt-0"
                          v-if="form.inventory == 'yes'"
                        >
                          Multiple Delivery
                        </b-form-checkbox>
                        
                      </b-col>

                      <b-col md="4" v-if="form.inventory == 'yes'">
                        <b-form-group label="Most Popular Item Count" class="required">
                          <b-form-input placeholder="Enter Number" v-model="form.most_popular_item_count" autocomplete="off"/>
                        </b-form-group>
                      </b-col>
                      
                    </b-row>

                    <b-row class="mt-2 margin_top_zero_mobile">
                      <b-col md="6">
                        <b-form-group label="Select required number of Order Validation Steps" class="required">
                          <b-form-select v-model="form.order_validation_steps" @change="orderValidationChange">
                            <b-form-select-option value="" disabled>Select</b-form-select-option>
                            <b-form-select-option :value="n" v-for="n in inventory_roles.length" :key="n">{{n}}</b-form-select-option>                          
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3" class="mt-2">
                            <b-form-checkbox
                          v-model="form.approval_requirement"
                          value="yes"
                          unchecked-value="no"
                          class="mb-0 mt-0"
                          @change="approvalRequirementChange()">
                            Approval Requirement
                          </b-form-checkbox>
                      </b-col>
                      <b-col md="3" v-if="form.approval_requirement == 'yes'">
                        <b-form-group label="Order Limit" class="required">
                          <b-form-input placeholder="Enter Number" v-model="form.order_limit" autocomplete="off"/>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row class="mt-2 margin_top_zero_mobile" v-for="(step, index) in form.order_validation_levels" :key="index">
                      <b-col md="6">
                        <b-form-group :label="'Level ' + (index + 1) + ' Validator'" class="required">
                          <b-form-select v-model="form.order_validation_levels[index]">
                            <b-form-select-option value="" disabled>Select</b-form-select-option>
                            <b-form-select-option :value="role" v-for="role in inventory_roles" :key="role.name">{{role.name}}</b-form-select-option>                          
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      
                    </b-row>

                    <!-- <b-row class="mt-2 margin_top_zero_mobile" v-if="form.inventory == 'yes'">
                      <b-col md="4">

                        <b-form-checkbox
                          v-model="form.inventory_notification"
                          value="yes"
                          unchecked-value="no"
                          class="mb-0 mt-2"
                          v-if="form.inventory == 'yes'"
                          @change="inventoryNotiSetReset()"
                        >
                          Enable Notification
                        </b-form-checkbox>
                  
                      </b-col>

                      <b-col md="4" v-if="form.inventory_notification == 'yes'">
                        <b-form-group label="Trigger on Day" class="required">
                          <b-form-select v-model="form.inventory_notification_day">
                            <b-form-select-option value='' >Select</b-form-select-option>
                            <b-form-select-option :value="day" v-for="day in 31">{{ day }}</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="form.inventory_notification == 'yes'">
                      
                        <b-form-group label="Trigger on Time" class="required">

                            <flat-pickr
                                v-model="form.inventory_notification_time"
                                class="form-control"
                                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', minuteIncrement:'10' }"
                            />

                        </b-form-group>
                      

                      </b-col>
                    </b-row> -->

                    <!-- <b-row class="mt-2 margin_top_zero_mobile" v-if="form.inventory == 'yes' && form.inventory_notification == 'yes'">
                      
                        <b-col md="6" v-if="form.inventory_notification == 'yes'">
                          <b-form-group label="Notification Trigger on Day" class="required">
                            <b-form-select v-model="form.inventory_notification_day">
                              <b-form-select-option value='' >Select</b-form-select-option>
                              <b-form-select-option :value="day" v-for="day in 31">{{ day }}</b-form-select-option>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" v-if="form.inventory_notification == 'yes'">
                          
                          <b-form-group label="Notification Trigger Time" class="required">

                              <flat-pickr
                                  v-model="form.inventory_notification_time"
                                  class="form-control"
                                  :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i' }"
                              />

                          </b-form-group>
                          

                        </b-col>
                      </b-row> -->
                </div>
            </div>

            <div class="inner_dv">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 150px" >SHIFT DETAIL</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">

                  <div class="fmlTable table-responsive" style="margin-bottom: 0px !important;">
                      <table class="table">
                          <thead>
                              <tr>
                                  <th scope="col" style="padding-top: 22px; width: 15%;">Shift Name</th>
                                  <th scope="col" style="padding-top: 22px; width: 15%;">Start Day</th>
                                  <th scope="col" style="padding-top: 22px; width: 15%;">End Day</th>
                                  <th scope="col" style="padding-top: 22px; width: 9%;">Start Time</th>
                                  <th scope="col" style="padding-top: 22px; width: 9%;">End Time</th>
                                  <th scope="col" style="padding-top: 22px; width: 12%;">Include Holiday</th>
                                  <th scope="col" style="padding-top: 22px; width: 10%;">Supervisor</th>
                                  <th scope="col" style="padding-top: 22px; width: 10%;">Crew</th>
                                  <th scope="col" style="width: 5%">
                                      <b-button variant="warning" @click="addShift">
                                          <feather-icon icon="PlusIcon" />
                                      </b-button>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(shift,index) in form.shifts" :key="index + 10">
                                  <td>
                                      <b-form-input placeholder="2 to 50 characters" v-model="shift.name"/>
                                  </td>
                                  <td>
                                      <b-form-select v-model="shift.start_day">
                                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                                          <b-form-select-option :value="day.name" v-for="day in weekdays" :key="day._id">{{day.name}}</b-form-select-option>
                                      </b-form-select>
                                  </td>
                                  <td>
                                      <b-form-select v-model="shift.end_day">
                                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                                          <b-form-select-option :value="day.name" v-for="day in weekdays" :key="day._id">{{day.name}}</b-form-select-option>
                                      </b-form-select>
                                  </td>
                                  <td>
                                      <flat-pickr
                                          v-model="shift.startTime"
                                          class="form-control"
                                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                                      />
                                  </td>
                                  <td>
                                      <flat-pickr
                                          v-model="shift.endTime"
                                          class="form-control"
                                          :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                                      />
                                  </td>
                                  <td class="text-center">
                                      <b-form-checkbox
                                          v-model="shift.holiday"
                                          value="yes"
                                          unchecked-value="no"
                                          class="custom-control-warning ml-4"
                                      />
                                  </td>
                                  <td>
                                      <b-form-input placeholder="" v-model="shift.supervisor"/>
                                  </td>
                                  <td>
                                      <b-form-input placeholder="" v-model="shift.crew"/>
                                  </td>
                                  <td class="text-center">
                                      <b-button class="danger_btn" @click="removeShift(index)">
                                          <feather-icon icon="XIcon"/>
                                      </b-button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>

                  <!-- <b-row class="mb-1">
                    <b-col md="2">
                      <b-form-group
                        label="Shift Name"
                        class="mb-0"
                      >
                      </b-form-group>

                    </b-col>

                    <b-col md="2">
                      <b-form-group
                        label="Start Day"
                        class="mb-0" 
                      > 
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group
                        label="End Day"
                        class="mb-0" 
                      > 
                      </b-form-group>
                    </b-col>

                    <b-col md="1">
                      <b-form-group
                        label="Start Time"
                        class="mb-0" 
                      > 
                      </b-form-group>
                    </b-col>

                    <b-col md="1">
                      <b-form-group
                        label="End Time"
                        class="mb-0"
                      >
                          
                      </b-form-group>
                    </b-col>

                    <b-col md="1">

                      <b-form-group
                        label="Include Holiday"
                        class="mb-0" 
                      > 
                      </b-form-group>
                    </b-col>

                    <b-col md="1">
                        
                      <b-form-group
                        label="Supervisor"
                        class="mb-0"
                      >
                      
                      </b-form-group>

                    </b-col>

                    <b-col md="1">
                        
                      <b-form-group
                        label="Crew"
                        class="mb-0"
                      >
                      
                      </b-form-group>

                    </b-col>

                    <b-col md="1" >
                      <b-button
                          variant="warning"
                          class="mt-0 ml-50 mr-0 float-right"
                          @click="addShift"
                        >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </b-col>
                  </b-row>



                  <b-row v-for="(shift,index) in form.shifts" :key="index + 10" class="mb-1">

                    <b-col md="2">
                      
                      <b-form-input
                        placeholder="2 to 50 characters"
                        v-model="shift.name"
                      />
                      

                    </b-col>

                    <b-col md="2">
                      
                      <b-form-select v-model="shift.start_day">

                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                        <b-form-select-option :value="day.name" v-for="day in weekdays" :key="day._id">{{day.name}}</b-form-select-option>
                        
                      </b-form-select>
                      
                    </b-col>

                    <b-col md="2">
                      
                      <b-form-select v-model="shift.end_day">

                        <b-form-select-option value="" disabled>Select</b-form-select-option>
                        <b-form-select-option :value="day.name" v-for="day in weekdays" :key="day._id">{{day.name}}</b-form-select-option>
                        
                      </b-form-select>
                      
                    </b-col>

                    <b-col md="1">
                      

                      
                      <flat-pickr
                        v-model="shift.startTime"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                      />

                    </b-col>

                    <b-col md="1">
                      

                      <flat-pickr
                        v-model="shift.endTime"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                      />
                      
                    </b-col>

                    <b-col md="1">
                      
                      <b-form-checkbox
                          v-model="shift.holiday"
                          value="yes"
                          unchecked-value="no"
                          class="custom-control-warning mt-1 one_rem_bottom_mobile"
                          style="margin-left: 40%"
                        />
                      
                      
                    </b-col>

                    <b-col md="2">
                        
                      
                      <b-row>
                        
                        <b-col md="6">
                          <b-form-input
                            placeholder=""
                            v-model="shift.supervisor"
                          />

                        </b-col>

                        <b-col md="6">
                          <b-form-input
                            placeholder=""
                            v-model="shift.crew"
                          />
                        </b-col>
                      </b-row>
                      

                    </b-col>

                    <b-col md="1" >
                      <b-button
                          variant="danger"
                          class="ml-50 mr-0 float-right"
                          @click="removeShift(index)"
                        >
                        <feather-icon icon="XIcon" />
                        
                      </b-button>
                    </b-col>


                  </b-row> -->
                </div>
            </div>


            <!-- <hr> -->

            <!-- <b-row class="mb-1 mt-2">
              <b-col md="12">
                <h4 class="card-title">Contract Period</h4>
              </b-col>
            </b-row> -->

            <div class="inner_dv">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 200px" >CONTRACT PERIOD</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">

                  <!-- <div class="fmTable table-responsive" style="margin-bottom: 0px !important;">
                      <table class="table">
                          <thead>
                              <tr>
                                  <th scope="col" style="width: 48%; padding-top: 22px;">Start Date</th>
                                  <th scope="col" style="width: 48%; padding-top: 22px;">End Date</th>
                                  <th scope="col" style="width: 4%">
                                      <b-button variant="warning" @click="addContractPeriod">
                                          <feather-icon icon="PlusIcon" />
                                      </b-button>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(contract,index) in form.contract_periods" :key="index + 90">
                                  <td>

                                      <b-form-datepicker
                                          v-model="contract.start_date"
                                          :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                          locale="en"
                                          
                                      />
                                  </td>
                                  <td>
                                      <b-form-datepicker
                                          v-model="contract.end_date"
                                          :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                          locale="en"
                                          
                                      />
                                  </td>
                                  <td class="text-center">
                                      <b-button class="danger_btn" @click="removeCP(index)">
                                          <feather-icon icon="XIcon" />  
                                      </b-button>
                                  </td>
                              </tr>
                          </tbody>
                      </table>
                      <hr>
                  </div> -->

                  <b-row class="mb-1">
                    <b-col md="4" class="mt-1 h5">
                      <b-form-group
                        label="Start Date"
                        class="mb-0"
                      >
                      </b-form-group>

                    </b-col>

                    <b-col md="4" class="mt-1 h5">
                      <b-form-group
                        label="End Date"
                        class="mb-0" 
                      > 
                      </b-form-group>
                    </b-col>


                    <b-col md="4" >
                      <b-button
                          variant="warning"
                          class="mt-0 ml-50 mr-0 float-right"
                          @click="addContractPeriod"
                        >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </b-col>
                  </b-row>



                  <b-row v-for="(contract,index) in form.contract_periods" :key="index + 90" class="mb-1">

                    <b-col md="4">
                      
                      <b-form-datepicker
                        placeholder="Start Date"
                        v-model="contract.start_date"
                        class="mb-1"
                        :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                        locale="en"
                      />
                      
                      

                    </b-col>

                    <b-col md="4">
                      
                      <b-form-datepicker
                      placeholder="End Date"
                        v-model="contract.end_date"
                        
                        :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                        locale="en"
                      />
                      
                    </b-col>

                    <b-col md="4" >
                      <b-button
                          variant="danger"
                          class="ml-50 mr-0 float-right"
                          @click="removeCP(index)"
                        >
                        <feather-icon icon="XIcon" />
                        
                      </b-button>
                    </b-col>


                  </b-row>

                  <b-row class="mb-1" v-if="form.site_type == 'hit_count'">
                    <b-col md="12" class="mb-1 mt-1">
                        <b-form-checkbox
                          v-model="form.kpi_contract"
                          value="yes"
                          unchecked-value="no"
                          class="custom-control-warning"
                          @change=updateKpi
                          
                        >
                          KPI Contract
                        </b-form-checkbox>
                        
                    </b-col>
                  </b-row>

                  <div v-if="form.kpi_contract == 'yes' && form.site_type == 'hit_count'" >
                    <b-row class="mb-1">
                      <b-col md="4">
                        <b-form-group
                          label="Number of minutes"
                          class="mb-0"
                        >
                        </b-form-group>

                      </b-col>


                      <b-col md="4" >
                        <b-button
                            variant="warning"
                            class="mt-0 ml-50 mr-0 float-left"
                            @click="updateMinute()"
                          >
                          <feather-icon icon="PlusIcon" />
                          
                        </b-button>
                      </b-col>
                    </b-row>



                    <b-row v-for="(m,index) in form.minutes" :key="index + 180" class="mb-1">

                      <b-col md="4">
                        
                        <b-form-input
                          placeholder=""
                          v-model="m.value"
                        
                        />
                        

                      </b-col>

                      

                      <b-col md="4" >
                        <b-button
                            variant="danger"
                            class="ml-50 mr-0 float-left"
                            @click="removeMinutes(index)"
                          >
                          <feather-icon icon="XIcon" />
                          
                        </b-button>
                      </b-col>


                    </b-row>
                  </div>


                  <b-row class="mb-2 mt-1">
                    <b-col md="6">
                      <b-form-group
                        label="Contract Sum"
                        
                      >

                      <b-input-group
                        class="input-group-merge"
                      >
                        <b-form-input
                          
                          v-model="form.contract_sum"
                          class="form-control-merge"
                          type="text"
                          :disabled="disable"
                          :placeholder="placeholder"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="updateDisable()"
                            
                          />
                        </b-input-group-append>
                      </b-input-group>

                      </b-form-group>

                    </b-col>

                  </b-row>
                </div>
            </div>

            <div class="inner_dv" v-if="showHideMenu('Attendance', 'child')">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 300px" >WORKING TIME INFORMATION</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">
                  <b-row class="mb-0">
                    <b-col md="6">

                      <div class="fmTable table-responsive mt-1" style="margin-bottom: 0px !important;">
                          <table class="table">
                              <thead>
                                  <tr>
                                      <th scope="col" style="width: 48%; padding-top: 22px;">Work Schedule <span class="text-danger">*</span></th>
                                      <th scope="col" style="width: 48%; padding-top: 22px;"></th>
                                      <th scope="col" style="width: 4%">
                                          <b-button variant="warning" @click="updateWorkSchedule()">
                                              <feather-icon icon="PlusIcon" />
                                          </b-button>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(m,index) in form.work_schedule" :key="index + 480" >
                                      <td>
                                          <flat-pickr
                                              placeholder="Start Time"
                                              v-model="m.startTime"
                                              class="form-control"
                                              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                                          />
                                      </td>
                                      <td>
                                          <flat-pickr
                                              placeholder="End Time"
                                              v-model="m.endTime"
                                              class="form-control"
                                              :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                                          />
                                      </td>
                                      <td class="text-center">
                                          <b-button class="danger_btn" @click="removeWorkSchedule(index)">
                                              <feather-icon icon="XIcon" />
                                          </b-button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>

                      <!-- <b-row class="mb-1">
                          <b-col md="8">
                            <b-form-group
                              label="Work Schedule"
                              class="mb-0 required"
                            >
                            </b-form-group>

                          </b-col>


                          <b-col md="4" >
                            <b-button
                                variant="warning"
                                class="mt-0 ml-50 mr-0 float-left mb-1"
                                @click="updateWorkSchedule()"
                              >
                              <feather-icon icon="PlusIcon" />

                            </b-button>
                          </b-col>
                      </b-row>

                      <b-row v-for="(m,index) in form.work_schedule" :key="index + 480" class="mb-1">

                          <b-col md="4">
                            

                            <flat-pickr
                                placeholder="Start Time"
                                v-model="m.startTime"
                                class="form-control mb-1"
                                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                              />
                            

                          </b-col>
                          <b-col md="4">

                            <flat-pickr
                                placeholder="End Time"
                                v-model="m.endTime"
                                class="form-control"
                                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                              />
                            

                          </b-col>

                          

                          <b-col md="4" >
                            <b-button
                                variant="danger"
                                class="ml-50 mr-0 float-left"
                                @click="removeWorkSchedule(index)"
                              >
                              <feather-icon icon="XIcon" />
                              
                            </b-button>
                          </b-col>
                      </b-row> -->

                    </b-col>

                    <b-col md="6">

                      <!-- <b-row class="mb-1">
                          <b-col md="8">
                            <b-form-group
                              label="Work Day (Per Week)"
                              class="mb-0 required"
                            >
                            </b-form-group>

                          </b-col>


                          <b-col md="4" >
                            <b-button
                                variant="warning"
                                class="mt-0 ml-50 mr-0 float-left mb-1"
                                @click="updateWorkDay()"
                              >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </b-col>
                      </b-row>

                      <b-row v-for="(m,index) in form.work_days" :key="index + 680" class="mb-1">

                          <b-col md="8">
                            
                            <b-form-input
                              placeholder="Ex - 5.5"
                              v-model="m.value"
                              autocomplete="off"
                              class="mb-1"
                            />
                            

                          </b-col>

                          

                          <b-col md="4" >
                            <b-button
                                variant="danger"
                                class="ml-50 mr-0 float-left"
                                @click="removeWorkDay(index)"
                              >
                              <feather-icon icon="XIcon" />
                              
                            </b-button>
                          </b-col>
                      </b-row> -->

                      <div class="fsmTable table-responsive mt-1" style="margin-bottom: 0px !important;">
                          <table class="table">
                              <thead>
                                  <tr>
                                      <th scope="col" style="width: 96%; padding-top: 22px;">Work Day (Per Week) <span class="text-danger">*</span></th>
                                      <th scope="col" style="width: 4%">
                                          <b-button variant="warning" @click="updateWorkDay()">
                                              <feather-icon icon="PlusIcon" />
                                          </b-button>
                                      </th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(m,index) in form.work_days" :key="index + 680">
                                      <td>
                                          <!-- <b-form-input placeholder="Ex - 5.5" v-model="m.value" autocomplete="off"/> -->
                                          <b-form-select v-model="m.value">
                                              <b-form-select-option value="" disabled>Select</b-form-select-option>
                                              <b-form-select-option value="1.0">1</b-form-select-option>
                                              <b-form-select-option value="2.0">2</b-form-select-option>
                                              <b-form-select-option value="3.0">3</b-form-select-option>
                                              <b-form-select-option value="4.0">4</b-form-select-option>
                                              <b-form-select-option value="5.0">5</b-form-select-option>
                                              <b-form-select-option value="5.5">5.5</b-form-select-option>
                                              <b-form-select-option value="6.0">6</b-form-select-option>
                                              <b-form-select-option value="7.0">7</b-form-select-option>
                                          </b-form-select>
                                      </td>
                                      <td class="text-center">
                                          <b-button class="danger_btn" @click="removeWorkDay(index)">
                                              <feather-icon icon="XIcon" />
                                          </b-button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>

                    </b-col>
                  </b-row>                  

                </div>
            </div>

        
            
            <!-- <b-row>
              <b-col md="12">
                <h4 class="card-title">Notifications </h4>
              </b-col>

            </b-row> -->

            <div class="inner_dv" v-if="showHideMenu('Feedback System', 'parent')">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 270px" >QR CODE CONFIGURATION</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">

                  <b-row class="mb-0">
                    <b-col md="6">
                      <b-form-checkbox v-model="form.show_custom_message" value="yes" unchecked-value="no" class="mb-0 mt-1" @input="form.message = ''">
                        Show Custom Message
                      </b-form-checkbox>
                    </b-col>

                    <b-col md="6">
                      <b-form-checkbox v-model="form.additional_information" value="yes" unchecked-value="no" class="mb-0 mt-1">
                        Get Additional Information
                      </b-form-checkbox>
                    </b-col>

                    <b-col md="12" v-if="form.show_custom_message == 'yes'">
                      <b-form-group label="Message" class="required legend_top_space_mobile mt-2">
                        <!-- <b-form-input placeholder="2 to 500 characters" v-model="form.message" autocomplete="off"/> -->
                        <b-form-textarea placeholder="2 to 500 characters" rows="2" v-model="form.message"/>
                      </b-form-group>
                    </b-col>

                  </b-row>

                </div>
            </div>

            <div class="inner_dv" v-if="showHideMenu('Schedule Work', 'parent') || showHideMenu('Schedule Works', 'child') || showHideMenu('SR Tasks', 'child') || showHideMenu('Service Request', 'child') || showHideMenu('Feedback System', 'parent') || showHideMenu('Defects', 'child') || showHideMenu('Defect', 'child') || checkOtherNotificationBlock()">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 180px" >NOTIFICATIONS</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">

                  <!-- SW -->

                  <div v-if="showHideMenu('Schedule Work', 'parent') || showHideMenu('Schedule Works', 'child')">
                    <h5 class="mt-2">Schedule Work Notification</h5>
                    <!-- Schedule Work --> 
                    <b-row class="mt-1 mb-1">

                      <b-col md="6" v-for="(n,i) in notifications" v-if="(n.type == 'sw_start' || n.type == 'sw_validate' || n.type == 'sw_completed' || n.type == 'sw_upcoming' || n.type == 'sw_end')" :key="n.order+300">

                        <b-form-group
                          :label="n.text"
                          class="mb-0"
                        >

                          <v-select
                            v-model="n.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="roles"
                            placeholder="Select"
                            :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                          />
                          
                          <hr>
                        </b-form-group>
                      </b-col>
                     
                      <b-col md="6">
                        <b-form-group
                          label="No. of minutes Before Task Start (Upcoming)"
                          class="required"
                        >
                          <b-form-input
                            placeholder=""
                            v-model="form.upcoming_sw"
                          />
                          <hr>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label="No. of minutes Before Task End"
                          class="required"
                        >
                          <b-form-input
                            placeholder=""
                            v-model="form.sw_end"
                          />
                          <hr>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  
                  <div v-if="showHideMenu('SR Tasks', 'child') || showHideMenu('Service Request', 'child')">
                    <h5 class="mt-2">Service Request Notification</h5>
                    <!-- Schedule Work --> 
                    <b-row class="mt-1 mb-1">

                      <b-col md="6" v-for="(n,i) in notifications" v-if="(n.type == 'sr_create' || n.type == 'sr_start' || n.type == 'sr_validate' || n.type == 'sr_completed' || n.type == 'sr_severity')" :key="n.order+300">

                        <b-form-group
                          :label="n.text"
                          class="mb-0"
                        >

                          <v-select
                            v-model="n.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="roles"
                            placeholder="Select"
                            :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                          />
                          
                          <hr>
                        </b-form-group>
                      </b-col>
                      
                    </b-row>
                  </div>

                  <div v-if="showHideMenu('Feedback System', 'parent')">
                    <h5 class="mt-2">Feedback Request Notification</h5>
                    <!-- Schedule Work --> 
                    <b-row class="mt-1 mb-1">

                      <b-col md="6" v-for="(n,i) in notifications" v-if="(n.type == 'fr_create' || n.type == 'fr_start' || n.type == 'fr_validate' || n.type == 'fr_completed')" :key="n.order+300">

                        <b-form-group
                          :label="n.text"
                          class="mb-0"
                        >

                          <v-select
                            v-model="n.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="roles"
                            placeholder="Select"
                            :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                          />
                          
                          <hr>
                        </b-form-group>
                      </b-col>
                      
                    </b-row>
                  </div>

                  <div v-if="showHideMenu('Defects', 'child') || showHideMenu('Defect', 'child')">
                    <h5 class="mt-2">Defect Notification</h5>
                    <!-- Schedule Work --> 
                    <b-row class="mt-1 mb-1">

                      <b-col md="6" v-for="(n,i) in notifications" v-if="(n.type == 'd_create' || n.type == 'd_start' || n.type == 'd_validate' || n.type == 'd_completed')" :key="n.order+300">

                        <b-form-group
                          :label="n.text"
                          class="mb-0"
                        >

                          <v-select
                            v-model="n.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="roles"
                            placeholder="Select"
                            :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                          />
                          
                          <hr>
                        </b-form-group>
                      </b-col>
                      
                    </b-row>
                  </div>
                  
                  <div v-if="checkOtherNotificationBlock()">
                    <h5 class="mt-2">Other Notification</h5>
                    <!-- Schedule Work --> 
                    <b-row class="mt-1 mb-1">

                      <!-- <b-col md="6" v-for="(n,i) in notifications" v-if="(n.type == 'sensor_email' || n.type == 'sensor_app' || n.type == 'qi_completed' || n.type == 'attendance')" :key="n.order+300"> -->
                      <b-col md="6" v-for="(n,i) in notifications" v-if="((n.type == 'sensor_email' && showHideMenu('Smart Sensors', 'parent')) || (n.type == 'sensor_app' && showHideMenu('Smart Sensors', 'parent')) || (n.type == 'qi_completed' && (showHideMenu('QI & Checklist', 'parent') || showHideMenu('Quality Management', 'parent'))) || (n.type == 'attendance' && showHideMenu('Attendance', 'child')))" :key="n.order+300">

                        <b-form-group
                          :label="n.text"
                          class="mb-0"
                        >

                          <v-select
                            v-model="n.value"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="roles"
                            placeholder="Select"
                            :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                          />
                          
                          <hr>
                        </b-form-group>
                      </b-col>


                      <b-col md="6" v-if="showHideMenu('Smart Sensors', 'parent')">
                        <b-form-group
                          label="Trigger Sensors Notification (In minutes)"
                          class="required"
                        >
                          <b-form-input
                            placeholder=""
                            v-model="form.sensor_notify_min"
                          />
                          <hr>
                        </b-form-group>
                      </b-col>

                      
                    </b-row>
                  </div>

                  

                  
                </div>
            </div>

            <div class="inner_dv">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 180px" >SITE LOGO</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">

                  <b-row class="mb-2">
                    <b-col md="12">
                      <b-form-checkbox v-model="form.use_logo" value="yes" unchecked-value="no" class="mb-0 mt-1" @input="resetLogo()">
                        Do you want to add logo ?
                      </b-form-checkbox>
                    </b-col>
                  </b-row>

                  <!-- <hr> -->
                  <b-row class="mt-2" v-if="form.use_logo == 'yes'">

                    <b-col md="4">

                      <b-form-group
                        label="Logo"
                        class="required full_height_width_img_mobile"
                      >
                        <b-media class="mb-2 mt-2 inline_media_mobile" @click="$refs.refInputEl.click()">
                          <template #aside>
                            <b-avatar
                              ref="company_logo"
                              :src="doc_icon"
                              variant="light-primary"
                              size="200px"
                              rounded
                              v-if="form.logo.image == ''"
                            />


                            <img :src="form.logo.image" width="200px" height="180" v-if="form.logo.image != ''" style="background: #ffffff">

                          </template>

                          <div class="d-flex flex-wrap">
                            <input
                              ref="refInputEl"
                              type="file"
                              class="d-none"
                              
                              @input="logoRenderer"
                            >
                          </div>
                        </b-media>

                        

                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group
                        label="App Logo"
                        class="required full_height_width_img_mobile"
                      >
                        <b-media class="mb-2 mt-2 inline_media_mobile" @click="$refs.refInputEl2.click()">
                          <template #aside>
                            
                            <b-avatar
                              ref="company_app_logo"
                              :src="doc_icon"
                              variant="light-danger"
                              size="200px"
                              rounded
                              v-if="form.appLogo.image == ''"
                              
                            />

                            <img :src="form.appLogo.image" width="200px" height="180" v-if="form.appLogo.image != ''" style="background: #ffffff">

                          </template>
                          <div class="d-flex flex-wrap">
                            <input
                              ref="refInputEl2"
                              type="file"
                              class="d-none"

                              @input="appLogoRenderer"
                            >
                          </div>
                        </b-media>


                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
            </div>

            
            <b-row class="mt-1">
              <b-col class="mobile_one_rem_top ">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button> 
                
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name: 'job-sites'})"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  <b-modal id="crop-modal" ref="crop-modal" centered title="Logo" hide-footer 
      :no-close-on-backdrop="true" @hide="resetModal">
      
        <template>
              <div>
                
                <div class="">
                  <section class="cropper-area">

                    <div class="img-cropper">

                     <vue-cropper :aspectRatio="1/1" ref="cropper" :src="temp.image" />
                      
                    </div>

                    <div class="pt-2 text-center float-left">
                      
                      <b-button variant="primary" class="mr-2 text-capitalize" @click.prevent="cropImage">CROP</b-button>

                      <b-button variant="danger" class="mr-2 text-capitalize" @click.prevent="reset">RESET</b-button>

                    </div>
                  </section>
                </div>
              </div>
        </template>
     
    </b-modal>

    <b-modal
        id="pass"
        ref="pass"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Verify Password"
        @ok="verifyPassword"
        no-close-on-backdrop
      >

        <b-alert
          v-model="popshowDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                  label="Password"
                  class="required"
                >
                  <b-form-input
                    placeholder=""
                    v-model="password"
                  />
              </b-form-group>
            </b-col>
          </b-row>      
        </b-form>
      </b-modal>



  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTextarea,BInputGroupAppend,BFormTimepicker,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS
//import VueTimepicker from 'vue2-timepicker'
//import 'vue2-timepicker/dist/VueTimepicker.css'
//import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
//import Datepicker from 'vuejs-datepicker';
import moment from "moment-timezone";
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Bus from "../../../event-bus";
export default {
  components: {
    //Datepicker,
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    VueGoogleAutocomplete,
    VueSimpleSuggest,
    BFormTextarea,
    //VueTimepicker,
    BInputGroupAppend,
    BFormTimepicker,
    flatPickr,
    BFormDatepicker,
    vSelect,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      weekdays:[],
      password:'',
      popshowDismissibleAlert:false,
      placeholder:'Click Edit Icon to Enable',
      temp:{
        image:null,
        name:'',
      },

      doc_icon: require('@/assets/images/doc.png'),
      
      error_message:null,
      showDismissibleAlert:false,
      agents:[],
      departments:[],
      form :{
        show_hide_qi: this.showHideMenu('Quality Management', 'parent'),
        show_hide_inventory: this.showHideMenu('Inventory', 'parent'),
        show_hide_attendance: this.showHideMenu('Attendance', 'child'),
        inventory_notification: 'no',
        inventory_notification_day: '',
        inventory_notification_time: '',
        kpi_performance: 80,
        qm_validator: '',
        inventory:'yes',
        multiple_delivery:'no',
        additional_info_defect:'no',
        restart_failed_task:'yes',
        start_expired_task:'yes',
        use_logo:'yes',
        site_type: 'hit_count',
        timestamp_on_image:'yes',
        allow_attendance:'no',
        attendance_on_day_off:'yes',
        task_status_delay_min:'',
        task_status_delay:'no',
        nfc_mode:'no',
        radius:'0',
        pdf_date_format: 'DD MMM YYYY',
        pdf_time_format: 'hh:mm A',
        attendance_mode:'camera',
        no_photo_reporting:'no',
        group_filter:'no',
        enable_selfie:'no',

        minutes:[{
          value:0
        }],
        grace_period: '0',
        prefix:'',
        site_name : '',
        address : '',
        postal_code : '',
        country:'',
        lat:'',
        long:'',
        manpower:'',
        logo : {
          image: '',
          name:''
        },

        appLogo : {
          image: '',
          name:''
        },
        qi:'',
        qi_number:'',
        managing_agent_name:'',
        department:'',
        remarks:'',
        kpi_contract:'no',
        contract_sum:'',
        shifts: [{
          name:'',
          start_day:'',
          end_day:'',
          startTime : '',
          endTime:'',
          holiday:'no',
          crew:'',
          supervisor:''
        }],
        
        contract_periods : [{
          start_date:'',
          end_date:'',
        }],
        geofence:[],
        work_schedule:[{startTime:null,endTime:null}],
        work_days:[{value:''}],
        show_custom_message:'no',
        additional_information:'no',
        message: '',
        severality_levels:[{
          name:'',
          minute:'',
        }],
        upcoming_sw:0,
        sw_end:0,
        sensor_notify_min:1,
        order_validation_steps: '',
        order_validation_levels: [],
        approval_requirement: 'no',
        order_limit: '',
      },
      

      clearButton:true,
      highlighted : {
          dates: [
            new Date()
          ]
      },
      disable:true,
      passwordToggleIcon:'EditIcon',
      notifications:[],
      //notification_option:[],

      mapStartLocation: { lat: 1.3521, lng: 103.8198 },
      zoom:17,
      roles:[],
      kpi_roles:[],
      paths:[],
      inventory_roles: [],
      
    }
  },
  methods : {
    checkOtherNotificationBlock(){
      var flag = false;

      this.notifications.forEach((n) => {
        if (
          (n.type === 'sensor_email' && this.showHideMenu('Smart Sensors', 'parent')) ||
          (n.type === 'sensor_app' && this.showHideMenu('Smart Sensors', 'parent')) ||
          (n.type === 'qi_completed' && (this.showHideMenu('QI & Checklist', 'parent') || this.showHideMenu('Quality Management', 'parent'))) ||
          (n.type === 'attendance' && this.showHideMenu('Attendance', 'child')) ||
          this.showHideMenu('Smart Sensors', 'parent')
        ) {
          flag = true;
        }
      });
      return flag;
    },

    isNumber($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        // only allow number 
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }
    },

    formSubmit(e){
      e.preventDefault();
      if(document.activeElement.getAttribute('type') === 'submit'){
        return this.$store.dispatch(POST_API, {
           data:{
             items:this.form,
             notifications:this.notifications,
             role:this.$store.getters.currentUser.role
           },
           api: '/api/add-job-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Bus.$emit('update-profile');

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name: 'job-sites'});
                });
                
            }
        });
      
      }
    },
    getAddressData(place, placeResultData, id){
      
      /*console.log(addressData, placeResultData, id);*/

      this.form.address = placeResultData.formatted_address;
      this.form.country = place.country;
      this.form.lat = place.latitude;
      this.form.long = place.longitude;

      this.mapStartLocation.lat = this.form.lat;
      this.mapStartLocation.lng = this.form.long;


      if (typeof place.postal_code != 'undefined') {
        this.form.postal_code = place.postal_code;
      }else{
        this.form.postal_code = '';
      }
      this.setGeofence();

    },
    logoRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'logo';

                  this.showImg();
            }
          }
      }
    },
    appLogoRenderer(event){
      
      var input = event.target;
      var files = event.target.files

      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.readAsDataURL(input.files[0]);
          var image = input.files[0];
          //console.log(image);

          if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
             
              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Please upload .png, .jpeg, .jpg images only',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;

          } else if (image.size > 2097152) {

              Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Maximum 2 MB files allowed to be upload.',
                  showConfirmButton: false,
                  timer: 1500
                })
              this.$refs.refInputEl.value=null;
          } else {
              reader.onload = (e) => {
                  this.temp.image = e.target.result;
                  this.temp.name = image.name;
                  this.modelbox = 'appLogo';

                  this.showImg();
            }
          }
      }
    },
    showImg(){
      this.$refs['crop-modal'].show();
    },
    resetModal(){
      
      if (this.modelbox == 'logo') {

        this.$refs.refInputEl.value=null;

      }else if(this.modelbox == 'appLogo'){

        this.$refs.refInputEl2.value=null;

      }
      

      this.temp.image = null;
      this.temp.name="";

      this.$refs['crop-modal'].hide();
    },
    cropImage() {
      
      if (this.modelbox == 'logo') {

        this.form.logo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.logo.name = this.temp.name;

      }else if(this.modelbox == 'appLogo'){

        this.form.appLogo.image = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.form.appLogo.name = this.temp.name;

      }
      
      this.$refs['crop-modal'].hide(); 
      
    },
    reset() {
      this.$refs.cropper.reset();
    },
    simpleSuggestionList() {
        
        return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/agents-suggestions'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                this.agents = this.$store.getters.getResults.data;
                return this.agents;
                
            }
        });
    },
    simpleDepartmentList() {
        
        return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/departments-suggestions'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                this.departments = this.$store.getters.getResults.data;
                return this.departments;
                
            }
        });
    },
    addShift(){
      this.form.shifts.push({
        name:'',
        start_day:'',
        end_day:'',
        /*startTime : {
          HH: '',
          mm: ''
        },
        endTime:{
          HH: '',
          mm: ''
        },*/
        startTime : '',
        endTime:'',
        holiday:'no',
        crew:'',
        supervisor:''
      })
    },
    removeShift(index){
      this.form.shifts.splice(index,1);
      if (this.form.shifts.length == 0) {

          this.form.shifts.push({
            name:'',
            start_day:'',
            end_day:'',
            startTime : '',
            endTime:'',
            holiday:'no',
            crew:'',
            supervisor:''
          })
      }
    },
    removeMinutes(index){
      this.form.minutes.splice(index,1);
      if (this.form.minutes.length == 0) {

          this.form.minutes.push({value:0})
      }
    },
    removeWorkSchedule(index){
      this.form.work_schedule.splice(index,1);
      if (this.form.work_schedule.length == 0) {

          this.form.work_schedule.push({startTime:null,endTime:null})
      }
    },
    removeWorkDay(index){
      this.form.work_days.splice(index,1);
      if (this.form.work_days.length == 0) {

          this.form.work_days.push({value:''})
      }
    },
    manpowerChange(){
      if(this.form.manpower == null || this.form.manpower == ''){
        this.form.shifts.forEach(item => {
          item.crew = '';
          item.supervisor = '';
        })
      }
    },
    addContractPeriod(){
      this.form.contract_periods.push({
        start_date:'',
        end_date:''
      })
    },
    removeCP(index){
      this.form.contract_periods.splice(index,1);
      if (this.form.contract_periods.length == 0) {

          this.form.contract_periods.push({
            start_date:'',
            end_date:''
          })
      }
    },
    customFormatter(date) {
      return moment(date).format('DD MMM YYYY');
    },
    updateDisable(){
      if (this.passwordToggleIcon == 'EditIcon') {

          this.popshowDismissibleAlert = false;

          this.$refs['pass'].show();

      }
    },

    verifyPassword(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
        data:{
              user_id: this.$store.getters.currentUser._id,
              password:this.password
            },
        api:"/api/verify-password",
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.popshowDismissibleAlert = true;
              } else {
                  
                  this.disable = false;
                  this.passwordToggleIcon = 'CheckSquareIcon';
                  this.placeholder = 'Enter Cotract Sum';
                  this.$refs['pass'].hide();
                
              }
          });
    },
    updateKpi(e){
      if (e == 'no') {
        this.form.minutes = [{
          value:0
        }];
      }
    },
    updateMinute(){

      this.form.minutes.push({
          value:0
        }); 
    },
    updateWorkSchedule(){

      this.form.work_schedule.push({
          startTime:null,endTime:null
        }); 
    },
    updateWorkDay(){

      this.form.work_days.push({
          value:''
        }); 
    },
    weekDaysData(){
      return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/week-days'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.weekdays = data;
                return this.weekdays;
            }
        });
    },
    getMasterNotification(){
      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/master-site-notifications'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.notifications = this.$store.getters.getResults.data;
                return this.notifications;
                
            }
        });
    },
    /*getnotificationOptions(){
      return this.$store.dispatch(POST_API, {
           data:{
           },
           api: '/api/site-notifications-options'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.notification_option = this.$store.getters.getResults.data;
                return this.notification_option;
                
            }
        });
    },*/
    datetype1() {
      return moment().tz('Asia/Singapore').format('MM/DD/YYYY');
    },
    datetype2() {
      return moment().tz('Asia/Singapore').format('DD/MM/YYYY');
    },
    datetype3() {
      return moment().tz('Asia/Singapore').format('DD MMM YYYY');
    },
    datetype4() {
      return moment().tz('Asia/Singapore').format('MMM DD YYYY');
    },
    /*timetype1() {
      return moment('13:00').tz('Asia/Singapore').format('hh:mm A');
    },
    timetype2() {
      return moment('13:00').tz('Asia/Singapore').format('HH:mm');
    }*/

    updateCoordinates(location){
      
      this.form.lat = location.latLng.lat();
      this.form.long = location.latLng.lng();
      
    },

    checkforms(e){

      if (e.length > 0 && e[e.length-1]._id == 'all-forms') {
        
        this.form.custom_forms = [];
        
        this.custom_forms.forEach(item => {
          if (item._id != 'all-forms') {
            this.form.custom_forms.push(item);
          }
        })
      }
    },
    getRoles(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:'site_setting'
           },
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.roles = this.$store.getters.getResults.data;
                
                return this.roles;
            }
        }); 
    },
    getRolesKPI(){
      return this.$store.dispatch(POST_API, {
           data:{},
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.kpi_roles = this.$store.getters.getResults.data;
                
                return this.kpi_roles;
            }
        }); 
    },
    updateEdited(mvcArray){
      let paths = [];
      for (let i=0; i<mvcArray.getLength(); i++) {
        let path = [];
        for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          path.push({lat: point.lat(), lng: point.lng()});
        }
        paths.push(path);
      }
      this.form.geofence = paths;
    },
    changeAttendance(){
      this.form.radius = '0';
      this.form.timestamp_on_image = 'yes';
      //this.paths = [];
      //this.form.geofence = [];

      /*if(this.form.attendance_mode == 'gps'){
        if (this.form.lat != '' && this.form.long != '') {
          this.setGeofence();
        }
      }*/
    },
    setGeofence(){

      var lat = this.form.lat;
      var long = this.form.long;

      var arr = [];

      var obj_1 = {
        lat : lat - 0.0000,
        lng : long + 0.00010,
      }
      
      arr.push(obj_1);
      
      var obj_2 = {
        lat : lat + 0.0005,
        lng : long + 0.00020,
      }

      arr.push(obj_2);

      var obj_3 = {
        lat : lat + 0.000,
        lng : long + 0.00020,
      }

      arr.push(obj_3);

      this.paths = [];
      this.paths.push(arr);
      this.form.geofence = [];
      this.form.geofence.push(arr);
    },
    breadCrumb(){
      
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to: null, // hyperlink
        text: 'Project Sites' // crumb text
      },
      {
        to: {name:'job-sites'}, // hyperlink
        text: 'Sites', // crumb text
      },
      {
        to: null, // hyperlink
        text: 'Add Project Site Details', // crumb text
        active: true
      }];

      return item;
    },
    attendanceModeReset(e){
      if (e == 'no') {
        this.form.nfc_mode = 'no';
        this.form.attendance_mode = 'camera'; 
        this.form.radius = '0';
        this.form.timestamp_on_image = 'yes';
        this.form.attendance_on_day_off = 'yes';
        this.form.grace_period = '0';
      }
    },
    addSeverality(){
      this.form.severality_levels.push({name:'',minute:''});
    },
    removeSeverality(i){
      this.form.severality_levels.splice(i,1);
      if (this.form.severality_levels.length == 0) {
          this.addSeverality();
      }
    },
    resetSeverality(){
      this.form.severality_levels = [{
        name:'',minute:''
      }]
      this.form.kpi_contract = 'no';
      this.form.minutes = [{value:0}];
    },
    resetLogo(){
      this.form.logo = {
        image: '',
        name:''
      };

      this.form.appLogo = {
        image: '',
        name:''
      };
    },
    inventorySetReset(){
      this.form.multiple_delivery = 'no';
      this.form.inventory_notification = 'no';
      this.form.inventory_notification_day = '';
      this.form.inventory_notification_time = '';
      this.form.most_popular_item_count = ''
    },
    inventoryNotiSetReset(){
      this.form.inventory_notification_day = '';
      this.form.inventory_notification_time = '';
    },
    orderValidationChange(){
      const num = this.form.order_validation_steps;
      if(num > this.form.order_validation_levels.length){
        for(let i = this.form.order_validation_levels.length; i < num; i++){
          this.form.order_validation_levels.push('');
        }
      } else if (num < this.form.order_validation_levels.length){
        for(let i = this.form.order_validation_levels.length; i > num; i--){
          this.form.order_validation_levels.pop();
        }
      }
    },
    approvalRequirementChange(){
      this.form.order_limit = '';
    },
    getRolesInventory(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:'inventory'
           },
           api: '/api/get-roles'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;

                this.inventory_roles = this.$store.getters.getResults.data;
            }
        }); 
    },
    
  },
  mounted(){
    this.simpleSuggestionList();
    this.simpleDepartmentList();
    this.weekDaysData();
    this.getMasterNotification();
    //this.getnotificationOptions();
    
    this.getRoles();
    this.getRolesKPI();
    this.getRolesInventory();
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .danger_btn{
      border-color: #ea5455 !important;
      background-color: #ea5455 !important;
  }

  ::v-deep .vdp-datepicker__calendar {
  position: fixed;
}
</style>
